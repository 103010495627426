import React from 'react'
import ScheduleTable from '../../Components/ScheduleTable/ScheduleTable'
import FirebaseUtil from '../../FirebaseUtil/FirebaseUtil'
import Utils from '../../Utils'
import './BookAppointmentScreen.css'
import FirebaseApp from 'firebase/app'
import ConfirmAppointment from '../../Components/ConfirmAppointment/ConfirmAppointment'
import { withRouter } from 'react-router'
import Calendar from 'react-calendar'
import 'react-calendar/dist/Calendar.css'
import ProgressDialog from '../../Components/ProgressDialog/ProgressDialog'
import NavHeader from '../../Components/NavHeader'

class BookAppointmentScreen extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            customerApp: null,
            customerId: '',
            doctors: [],
            clinicId: '',
            doctorPickerSelectedVal: 0,
            currentScreen: 'slot', // slot, confirm
            appointmentId: '',
            currentDate: new Date(),
            showCalendar: false,
            postCovidType: 'new' // new / renew
        }
        this.firebaseUtil = FirebaseUtil.initFirebase()
        this.database = FirebaseApp.database(this.firebaseUtil.firebaseApp)
    }

    loadCustomerId() {
        this.unsubscribeAuthState = FirebaseApp.auth(this.firebaseUtil.firebaseApp).onAuthStateChanged((user) => {
            const phoneNumber = this.firebaseUtil.Auth.currentUser.phoneNumber.replace('+91', '')
            this.database
                .ref('/customer/')
                .orderByChild('profile/phone')
                .equalTo(phoneNumber)
                .once('value')
                .then(snap => {
                    if (snap.exists() && Object.keys(snap.val()).length > 0) {
                        if (!snap.hasChild('name')) {
                            // head to profile section as name not found and must be updated!
                        }

                        this.setState({
                            customerId: Object.keys(snap.val())[0]
                        })
                    } else console.log('Doesntexist')
                }).catch(err => {
                    console.log('Error getting customerId', err)
                }).finally(() => {
                    this.loadConsumerAppData()
                })
        })
    }

    componentDidMount() {
        document.title = 'Book Appointment'

        this.loadCustomerId()
    }

    componentWillUnmount() {
        this.unsubscribeAuthState()
    }

    loadConsumerAppData() {
        this.database
            .ref('/customerApp')
            .once('value')
            .then(snap => {
                console.log(snap.val())
                this.setState({ customerApp: snap.val() }, () => {
                    // loading other things
                    console.log(this.state.customerApp)
                    this.loadDoctorNames()
                })
            }).catch(err => {
                console.log(err)
            })
    }

    getProviderID() {
        return `${this.state.clinicId}_${this.state.doctors[this.state.doctorPickerSelectedVal].key}`
    }

    loadDoctorNames() {
        const clinicId = Object.keys(this.state.customerApp.clinics)[0]
        const doctorObj = this.state.customerApp.clinics[clinicId].doctors
        const doctors = Object.keys(doctorObj).map(key => {
            return {
                key: key,
                time: this.state.customerApp.clinics[clinicId].doctors[key].time
            }
        })

        this.setState({ clinicId: clinicId, doctors: doctors });
        this.forceUpdate();

        doctors.forEach(snap => {
            console.log('Fetching for:', this.state.clinicID, snap)
            this.database
                .ref(`/provider/${clinicId}/doctors/${snap.key}/profile`)
                .once('value')
                .then(profile => {
                    console.log('Got', profile.val())
                    if (profile.exists()) {
                        const doctors = this.state.doctors
                        const index = doctors.findIndex(x => x.key === snap.key)
                        doctors[index] = {
                            ...doctors[index],
                            ...profile.val()
                        }
                        console.log(profile.val())
                        console.log(doctors)
                        this.setState({
                            doctors: doctors
                        })
                        this.forceUpdate()
                    }
                })
        })
    }

    OpenCalendar() {
        this.setState({
            showCalendar: !this.state.showCalendar
        })
    }

    render() {
        return (
            <div>
                <NavHeader title={this.state.currentScreen === 'slot'
                    ? 'Book Appointment' : 'Confirm Appointment'} />

                <div className='Book-MainContainer SecondaryBg PatternBg'>
                    {this.state.currentScreen === 'slot'
                        ? this.GetSlotInfoScreen()
                        : <ConfirmAppointment
                            doctor={this.state.doctors[this.state.doctorPickerSelectedVal]}
                            customerId={this.state.customerId}
                            appointmentId={this.state.appointmentId}
                            postCovidType={this.state.postCovidType}
                            currentDate={this.state.currentDate}
                            GoBack={() => this.GoBack(true)}
                        />}
                </div>
            </div>
        )
    }

    GoBack(toHome) {
        if (toHome) {
            this.props.history.push('/Home')
        } else {
            if (this.state.currentScreen === 'confirm') {
                this.setState({
                    currentScreen: 'slot'
                })
            } else {
                this.props.history.push('/Home')
            }
        }
    }

    GetSlotInfoScreen() {
        return (
            <>
                <div className='Book-InnerContainer'>

                    {this.state.showCalendar &&
                        <Calendar
                            value={this.state.currentDate}
                            onChange={(date) => this.setState({ currentDate: date, showCalendar: false })}
                            minDate={new Date()}
                        />}
                    <div className='Book-OptionsContainer'>
                        <select className='Book-Type' value={this.state.postCovidType} onChange={(ev) => this.setState({ postCovidType: ev.target.value })}>
                            <option value='new'>Post Covid Rehab (new)</option>
                            <option value='renew'>Post Covid Rehab (renew)</option>
                        </select>

                        <select
                            className='Book-Type'
                            value={this.state.doctorPickerSelectedVal}
                            onChange={(ev) => {
                                this.setState({ doctorPickerSelectedVal: ev.target.value });
                                console.log('selected', ev.target.value);
                            }}
                        >
                            {this.state.doctors.map((doc, index) => {
                                return (
                                    <option key={index} value={index}>{doc.name}</option>
                                )
                            })}
                        </select>
                    </div>

                    {this.state.doctors.length > 0 &&
                        <ScheduleTable
                            currentDate={this.state.currentDate}
                            providerId={this.getProviderID()}
                            times={this.state.doctors[this.state.doctorPickerSelectedVal].time}
                            onSelected={(appointmentId) => {
                                this.setState({ appointmentId: appointmentId, currentScreen: 'confirm' });
                                console.log(appointmentId);
                            }}
                            OpenCalendar={() => this.OpenCalendar()}
                        />}

                    {this.state.doctors.length === 0 &&
                        <ProgressDialog head='Please Wait' body='Loading Available Appointments' />}
                </div>
            </>
        )
    }
}

export default withRouter(BookAppointmentScreen)
