

function ChatBubble(props){

    return(
        <div style={{maxWidth: window.innerWidth > 600 ? "50%" : "70%", padding: "10px", display: "flex", flexDirection: "column", alignSelf: props.docName ? "flex-start" : "flex-end"}}>
            {props.docName &&
            <p style={{alignSelf: "flex-start", margin: "0", padding: "0", verticalAlign: "middle", fontSize: "1em"}}><span className="material-icons" style={{marginRight: "5px", fontSize: "1em"}}>person</span>{props.docName}</p>}

            {props.message.startsWith('http') ? 
                <p onClick={() => window.open(props.message)} className="Clickable" style={{width: "100%", verticalAlign: "middle", padding: "10px", backgroundColor: props.appColor, color: "white", textAlign: "justify", borderRadius: "10px", margin: "0"}}>
                    <span class="material-icons" style={{marginRight: "10px", fontSize: '1.4em', verticalAlign: "middle"}}>article</span>
                    File
                </p>
                :
                <p style={{width: "100%", padding: "10px", paddingRight: "40px", backgroundColor: props.appColor, color: "white", textAlign: "justify", borderRadius: "10px", margin: "0"}}>
                    {props.message}
                </p>
            }
            
            
            <p style={{alignSelf: "flex-end", margin: "0", padding: "0", color: "black", fontSize: "0.8em"}}>
                {props.time}
            </p>
        </div>
    )
}


export default ChatBubble;
