const RoutesConstants = {
  Splash: '/',
  Login: '/login',
  Home: '/home',
  Book: '/book',
  Appointments: '/appointments',
  Exercises: '/exercises',
  Doctors: '/doctors',
  Profile: '/profile',
  Request: '/requestAppointment',
  Connect: '/connect'
}

export default RoutesConstants
