import { useState } from 'react';
import Utils from '../../Utils';
import './AppointmentEntry.css';

export default function AppointmentEntry(props){
    const [noteVisible, setNoteVisible] = useState(false);
    return(
        <div className="AppEntry-Main" onClick={() => setNoteVisible(!noteVisible)}>
            <div className="AppEntry-Head">
                <h2>{props.appointment.doctorName}</h2>
                <p>{Utils.getReadableTimeFromTimeStamp(props.appointment.date)}</p>
            </div>

            <p className="AppEntry-Info">Status: {props.appointment.status}</p>
            <p className="AppEntry-Info">Clinic: {props.appointment.clinicName}</p>
            
            {noteVisible &&
                <p className="AppEntry-Info">Note: {props.appointment.appointmentDetails.notes}</p>
            }
        </div>
    )
}