const Utils = {
  toArray: function (map) {
    const array = []

    if (map) {
      const keys = Object.keys(map)
      const values = Object.values(map)
      keys.forEach((key, index) => {
        if (typeof values[index] === 'object') {
          const obj = values[index]
          obj.key = key
          array.push(obj)
        } else {
          array.push({
            key: keys[index],
            value: values[index]
          })
        }
      })
    }
    return array;
  },
  todaysDate: () => {
    const date = new Date();
    const day = date.getDate();
    let month = date.getMonth();
    const year = date.getFullYear();

    return Utils.getMonthNameFromIndex(month) + ' ' + day + ', ' + year;
  },
  getDateFromTime: time => {
    const date = new Date();
    date.setTime(time);
    const month = Utils.getMonthNameFromIndex(date.getMonth());
    const year = date.getFullYear();
    const day = date.getDate();
    let hour = date.getHours();
    const ampm = hour < 12 ? 'am' : 'pm';
    if (hour > 12) {
      hour -= 12;
    }
    let min = date.getMinutes();
    min = new String(min).length === 1 ? '0' + min : min;
    hour = new String(hour).length === 1 ? '0' + hour : hour;
    // return day + ' ' + month + ' ' + year + ', ' + hour + ':' + min + ' ' + ampm;
    return day + ' ' + month;
  },
  getMonthNameFromIndex: (index) => {
    const months = [
      "JAN",
      "FEB",
      "MAR",
      "APR",
      "MAY",
      "JUN",
      "JUL",
      "AUG",
      "SEP",
      "OCT",
      "NOV",
      "DEC",
    ];
    return months[index];
  },
  getCapsFullMonthNameFromIndex: (index) => {
    const months = [
      "JANUARY",
      "FEBURARY",
      "MARCH",
      "APRIL",
      "MAY",
      "JUNE",
      "JULY",
      "AUGUST",
      "SEPTEMBER",
      "OCTOBER",
      "NOVEMBER",
      "DECEMBER",
    ];
    return months[index];
  },
  getWeekDayFromIndex: (index) => {
    const days = [
      "SUNDAY",
      "MONDAY",
      "TUESDAY",
      "WEDNESDAY",
      "THURSDAY",
      "FRIDAY",
      "SATURDAY",
    ];
    return days[index];
  },
  getReadableTimeFromTimeStamp: (time) => {
    const date = new Date();
    date.setTime(parseInt(time, 10));
    console.log(date, date.getHours())
    let dt = date.getDate();
    let mon = Utils.getMonthNameFromIndex(date.getMonth());
    let hr = date.getHours() <= 12 ? date.getHours() : (date.getHours() - 12);
    let mn = date.getMinutes();

    dt = dt.toString().length === 1 ? '0' + dt : dt;
    hr = hr.toString().length === 1 ? '0' + hr : hr;
    mn = mn.toString().length === 1 ? '0' + mn : mn;

    return (
      dt +
      ' ' +
      mon +
      ', ' +
      hr +
      ':' +
      mn +
      ((date.getHours() < 12) ? ' AM' : ' PM')
    );
  },
  getShortReadableTimeFromTimeStamp: (time, year = false) => {
    const date = new Date();
    date.setTime(parseInt(time, 10));
    if (year) {
      return date.getDate() + ' ' + Utils.getMonthNameFromIndex(date.getMonth()).substr(0, 3) + ', ' + date.getFullYear()
    }
    return date.getDate() + ' ' + Utils.getMonthNameFromIndex(date.getMonth()).substr(0, 3);
  },
  getReadableTimeFromAppointmentId: (id = '') => {
    const time = id.split('_')[2];
    const year = time.substr(0, 4);
    const month = time.substr(4, 2);
    const day = time.substr(6, 2);
    const hr = time.substr(8, 2);
    const mn = time.substr(10, 2);
    return (
      day +
      ' ' +
      Utils.getMonthNameFromIndex(parseInt(month, 10) - 1) +
      ' ' +
      year +
      ', ' +
      hr +
      ':' +
      mn
    );
  },
  getTimeStampFromAppointmentId: (id = '') => {
    const time = id.split('_')[2];
    const year = parseInt(time.substr(0, 4), 10);
    const month = parseInt(time.substr(4, 2), 10);
    const day = parseInt(time.substr(6, 2), 10);
    const hr = parseInt(time.substr(8, 2), 10);
    const mn = parseInt(time.substr(10, 2), 10);
    const sec = parseInt(time.substr(12, 2), 10);

    const date = new Date(year, month - 1, day, hr, mn, sec, 0);
    return date.getTime();
  },
  getDateTimeFromAppointmentKey: key => {
    const d = key.split('_')[2];
    const date = new Date();
    date.setFullYear(parseInt(d.substring(0, 4), 10));
    date.setMonth(parseInt(d.substring(4, 6), 10) - 1);
    date.setDate(parseInt(d.substring(6, 8), 10));
    date.setHours(parseInt(d.substring(8, 10), 10));
    return date.getTime();
  },
  makeFirstLetterCap: (str = '') => {
    let firstLetter = str.substr(0, 1);
    firstLetter = firstLetter.toUpperCase();
    return firstLetter + str.substr(1);
  },
  convertMilitaryTimeTo12Hr: (militaryTime = '') => {
    let mMilitaryTime = militaryTime;
    if (mMilitaryTime.length === 3) {
      mMilitaryTime = `0${militaryTime}`;
    }
    let hr = parseInt(mMilitaryTime.substr(0, 2), 10);
    const meredian = hr > 12 ? 'PM' : 'AM';
    let min = parseInt(mMilitaryTime.substr(2, 2), 10);

    // converting to 12 hr
    hr = hr > 12 ? hr - 12 : hr;
    // converting to 2 digit
    hr = hr < 10 ? `0${hr}` : hr;
    min = min < 10 ? `0${min}` : min;

    return `${hr}:${min} ${meredian}`;
  },
  getDateTimeFromKey: function (key) {
    const d = key.split('_')[2]
    const date = new Date()
    date.setFullYear(parseInt(d.substring(0, 4)))
    date.setMonth(parseInt(d.substring(4, 6)) - 1)
    date.setDate(parseInt(d.substring(6, 8)))
    date.setHours(parseInt(d.substring(8, 10)))
    date.setMinutes(parseInt(d.substring(10, 12)))
    date.setSeconds(parseInt(d.substring(12, 14)))
    return date.getTime()
  },
  getFormattedDate: (milliSeconds, timeType) => {
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ]
    // milliSeconds -= 5.5 * 60 * 60 * 1000;
    const date = new Date(milliSeconds);
    let formatted =
      date.getDate() + " " + months[date.getMonth()].substring(0, 3);

    if (timeType === "fullTime") {
      formatted =
        date.getDate() +
        " " +
        months[date.getMonth()].substring(0, 3) +
        ", ";
      let minutes = date.getMinutes();
      if (!minutes) {
        minutes = "00";
      } else {
        minutes = "30"
      }
      if (date.getHours() === 12) {
        return formatted + "12:00 pm";
      } else if (date.getHours() > 12) {
        return formatted + (date.getHours() - 12) + ":" + minutes + " pm";
      } else {
        return formatted + date.getHours() + ":" + minutes + " am";
      }
    } else if (timeType === "onlyTime") {
      formatted = "";
      let minutes = date.getMinutes();
      if (!minutes) {
        minutes = "00";
      }
      if (date.getHours() === 12) {
        return formatted + "12:00 pm";
      } else if (date.getHours() > 12) {
        return formatted + (date.getHours() - 12) + ":" + minutes + " pm";
      } else {
        return formatted + date.getHours() + ":" + minutes + " am";
      }
    }
    return formatted;
  },
  getTodaysSessionStartKey: (activeProgram) => {
    const date = new Date();
    let day = date.getDate();
    day = new String(day).length === 1 ? '0' + day : day;
    let month = date.getMonth() + 1;
    month = new String(month).length === 1 ? '0' + month : month;
    const year = date.getFullYear();

    console.log(activeProgram + '_' + year + month + day + '0000');
    return activeProgram + '_' + year + month + day + '0000';
  },
  getTodaysSessionEndKey: (activeProgram) => {
    const date = new Date();
    let day = date.getDate();
    day = new String(day).length === 1 ? '0' + day : day;
    let month = date.getMonth() + 1;
    month = new String(month).length === 1 ? '0' + month : month;
    const year = date.getFullYear();

    return activeProgram + '_' + year + month + day + '2359';
  },
  getMonthShortNameFromIndex: (index) => {
    switch (index) {
      case 0:
        return 'JAN';
      case 1:
        return 'FEB';
      case 2:
        return 'MAR';
      case 3:
        return 'APR';
      case 4:
        return 'MAY';
      case 5:
        return 'JUN';
      case 6:
        return 'JUL';
      case 7:
        return 'AUG';
      case 8:
        return 'SEP';
      case 9:
        return 'OCT';
      case 10:
        return 'NOV';
      case 11:
        return 'DEC';
      default:
        return 'JAN';
    }
  },
  getDobFormat: function (milliseconds) {
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ]
    const date = new Date(milliseconds)
    date.setHours(date.getHours() + 6)
    date.setMinutes(date.getMinutes() - 30)
    return months[date.getMonth()].substr(0, 3) + ' ' + date.getDate() + ', ' + date.getFullYear()
  }
}

export default Utils;