import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import RoutesConstants from '../../RoutesConstants'
import FirebaseUtil from '../../FirebaseUtil/FirebaseUtil'

function BookAppts(props) {
  const { routeImages } = props
  const firebaseApp = FirebaseUtil.initFirebase().firebaseApp

  const [bookAppointment, setBookAppointment] = useState(() =>
    firebaseApp.database().ref().child('customerApp/bookAppts').once('value').then(x => {
      setBookAppointment({
        ...x.val(),
        link: RoutesConstants.Book,
        key: 'bookAppointment'
      })
    }))

  const history = useHistory()

  return (
    (bookAppointment && bookAppointment.name)
      ? <div class='row' style={{ marginBottom: '0px' }}>
        <div
          class='col s12 m7' onClick={() => history.push(bookAppointment.link)}
          key={bookAppointment.key}
        >
          <div
            class='card home-tiles Clickable'
            style={
              { backgroundImage: 'url(' + routeImages[bookAppointment.key || './logo512.png'] + ')' }
            }
          >
            <span class='card-title'>{bookAppointment.name}</span>
          </div>
        </div>
      </div> : null
  )
}

export default BookAppts
