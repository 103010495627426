import React from 'react';
import FirebaseUtils from '../../FirebaseUtil/FirebaseUtil';
import FirebaseApp from 'firebase/app';
import Logo from '../../Resources/logo.png';
import './LoginScreen.css';
import { withRouter } from 'react-router';

class LoginScreen extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            phoneNumber: '',
            otp: '',
            showScreen: 'phone', // phone or otp
            loading: false,
            error: null
        }
        this.firebaseUtils = FirebaseUtils.initFirebase();
    }

    setupLogin() {
        if (this.recaptchaVerifier)
            return;

        this.recaptchaVerifier = new FirebaseApp.auth.RecaptchaVerifier('Login-Recaptcha', {
            size: 'invisible',
            callback: (res) => { }
        }, this.firebaseUtils.firebaseApp);
    }

    sendOTP() {
        if (this.state.phoneNumber.length < 10) {
            this.setState({
                error: 'Please enter a valid phone number'
            });
            return;
        }

        this.setState({
            loading: true
        });

        this.firebaseUtils.Auth.signInWithPhoneNumber(`+91${this.state.phoneNumber}`, this.recaptchaVerifier)
            .then((confirmationResult) => {
                this.confirmationResult = confirmationResult;
                this.setState({
                    loading: false,
                    showScreen: 'otp'
                });
                console.log("code sent");
            }).catch((error) => {
                console.log('signin error', error);
                this.setState({
                    loading: false,
                    error: "Error sending OTP, try again",
                });
            });
    }

    AddUser() {
        FirebaseApp.functions(this.firebaseUtils.firebaseApp)
            .httpsCallable('addCustomer')({ customer: { phone: this.state.phoneNumber } })
            .then(rslt => {
                console.log(rslt)
                console.log('User added successfully', rslt.data);
                localStorage.setItem('customer', JSON.stringify(rslt.data.result.customer))
                if (rslt.data && rslt.data.result.success) {
                    this.props.history.push('/profile');
                } else {
                    this.props.history.push('/home');
                }
            })
            .catch(err => {
                console.log('Error adding user', err);
            })
    }

    verifyOTP() {
        if (this.state.otp.length < 6) {
            this.setState({
                error: 'Please enter a valid 6-Digit OTP'
            });
            return;
        }

        this.setState({
            loading: true
        });

        console.log(this.confirmationResult, this.state.otp);

        this.confirmationResult.confirm(this.state.otp)
            .then(rslt => {
                this.setState({
                    loading: false
                });
                console.log("Code verified");
                this.AddUser();
            })
            .catch(err => {
                console.log('error verifying code', err);
                this.setState({
                    loading: false,
                    error: "Error verifying OTP, please check OTP entered",
                });
            })
    }

    componentDidMount() {
        document.title = window.master.name

        this.setupLogin();
    }

    loginBtnClicked() {
        this.setState({
            error: null
        });

        try {
            console.log('curr', this.state.showScreen);
            if (this.state.showScreen === 'phone')
                this.sendOTP();
            else
                this.verifyOTP();
        } catch (ex) {
            console.log('got ex', ex);
        }
    }

    render() {
        return (
            <div className="Login-MainContainer SecondaryBg">
                <div className="Login-Banner PrimaryBg  z-depth-2 SecondaryText center-align">
                    <img src={Logo} alt='' />
                    <h3>{window.master.name}</h3>
                    <p>We are Happy to have you with us</p>
                </div>

                <div className="Login-InnerContainer PatternBg SecondaryBg  z-depth-0">
                    {this.state.showScreen === 'phone' ?
                        this.getPhoneScreen()
                        :
                        this.getOTPScreen()
                    }

                    {!this.state.loading &&
                        <button onClick={() => this.loginBtnClicked()} className="Login-SendBtn btn-floating btn-large waves-effect waves-light PrimaryBg"><i className="material-icons">arrow_forward_ios</i></button>
                    }

                    <div id="Login-Recaptcha"></div>
                </div>
            </div>
        );
    };

    getPhoneScreen() {
        return (
            <>
                <h4 className="hide-on-small-only">Enter your phone number</h4>
                <br />

                <div className="PhoneInput">
                    <p className="PrimaryText no-pad">+91</p>
                    <input value={this.state.phoneNumber} type="phone" maxLength="10" onChange={ev => this.setState({ phoneNumber: ev.target.value })} />
                </div>

                {this.state.error &&
                    <p className="red-text">{this.state.error}</p>}

                <p className="Login-Instruction">We will send you a one time password via SMS.</p>
            </>
        )
    }

    getOTPScreen() {
        return (
            <>
                <h4 className="hide-on-small-only">OTP Sent!</h4>
                <br />

                <div className="PhoneInput">
                    <input value={this.state.otp} type="phone" maxLength="6" onChange={ev => this.setState({ otp: ev.target.value })} />
                </div>

                {this.state.error &&
                    <p className="red-text">{this.state.error}</p>}

                <p className="Login-Instruction">An OTP has been sent to +91-{this.state.phoneNumber} <br />
                    If this isn't your number then <a onClick={() => this.setState({ showScreen: 'phone' })} href="javascript:void(0);">Click here</a>
                </p>
            </>
        )
    }
}

export default withRouter(LoginScreen);
