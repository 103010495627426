import React from 'react';
import { withRouter } from 'react-router-dom';
import FirebaseUtil from '../../FirebaseUtil/FirebaseUtil';
import Logo from '../../Resources/logo.png';
import RoutesConstants from '../../RoutesConstants';
import './SplashScreen.css';
import { useHistory } from 'react-router-dom'

function SplashScreen(props) {

    const { firebaseApp } = FirebaseUtil.initFirebase();
    firebaseApp.auth().onAuthStateChanged(function (user) {
        if (user) {
            props.history.push(RoutesConstants.Home)
        } else {
            props.history.push(RoutesConstants.Login)
        }
    })

    return <div className="Splash-MainContainer PrimaryBg" >
        <img alt='' className="Splash-logo responsive-img SecondaryText" src={Logo} />
    </div>
}

export default withRouter(SplashScreen);
