import { useEffect, useState } from "react";
import { withRouter } from "react-router";
import FirebaseApp from 'firebase/app';
import FirebaseUtil from "../../FirebaseUtil/FirebaseUtil";
import ChatBubble from "./ChatBubble";
import { Link } from "react-router-dom";
import RoutesConstants from "../../RoutesConstants";
import ProgressDialog from "../../Components/ProgressDialog/ProgressDialog";
import Utils from "../../Utils";

function ScrollToBottom(){
    let chatContainer = document.getElementById('ChatContainer');
    chatContainer.scrollTop = chatContainer.scrollHeight;
}

// returns appoitnemnt data if available else false
async function getLastAppointment(database, customerData){
    let snap = await database.ref('/appointments')
        .orderByChild('/customerId')
        .equalTo(customerData.customerId)
        .once('value');
    
    if(snap.exists()){
        let recentAppointment = null;
        snap.forEach(appointment => {
            if(recentAppointment == null){
                recentAppointment = appointment;
            }else{
                if(recentAppointment.child('date').val() <= appointment.child('date').val()){
                    recentAppointment = appointment;
                }
            }
        });

        let currTime = new Date().getTime();
        let FiveDays = 15 * 24 * 60 * 60 * 1000;
        if(currTime <= (recentAppointment.child('date').val() + FiveDays)){
            let json = recentAppointment.toJSON();
            return {
                ...json,
                clinicId: json.providerId.split('_')[0],
                doctorId: json.providerId.split('_')[1]
            }
        }
    }

    return false;
}

async function loadCustomerData(database, phone){
    let snap = await database.ref('/customer').orderByChild('/profile/phone')
        .equalTo(phone)
        .once('value');

    let customerId = Object.keys(snap.val())[0]
    if (snap.exists() && Object.keys(snap.val()).length > 0) {
        return {
            customerId: customerId,
            name: snap.child(customerId).child('/profile/name').val(),
            phone: snap.child(customerId).child('/profile/phone').val()
        }
    } else console.log('user Doesntexist')

    return false;
}

async function LoadData(database, customerData){
    let recentAppointment = await getLastAppointment(database, customerData);
    console.log('recent', recentAppointment);
    if(recentAppointment){
        console.log('recentAppointment', recentAppointment);
        let clinicProfile = await database.ref(`/provider/${recentAppointment.clinicId}/profile`).once('value');
        let doctorProfile = await database.ref(`/provider/${recentAppointment.clinicId}/doctors/${recentAppointment.doctorId}/profile`).once('value');

        return {
            doctorName: doctorProfile.child('name').val(),
            clinicLocation: `${clinicProfile.child('locality').val()}, ${clinicProfile.child('city').val()}`,
            clinicId: recentAppointment.clinicId,
            doctorId: recentAppointment.doctorId
        }
    }
    return "NO_APPOINTMENT";
}

function ShowConnectDisabled(){
    let ref = document.getElementById('NoConnectModal');

    let modal = window.M.Modal.init(ref, {dismissible: false});
    modal.open();
}

function ShowUpdateProfile(){
    let ref = document.getElementById('NoProfileModal');

    let modal = window.M.Modal.init(ref, {dismissible: false});
    modal.open();
}

function LoadChatMessages(database, customerId, onMsgAdded){
    console.log('Looking for msg in ', `/chat/${customerId}/`);
    database.ref(`/chat/${customerId}/`)
        .orderByKey()
        .on("child_added", (snap) => {
            
            console.log('Got message', snap.toJSON());
            onMsgAdded({
                date: Utils.getReadableTimeFromTimeStamp(parseInt(snap.key, 10)),
                msg: snap.child('msg').val(),
                from: snap.child('from').val()
            });
        });
}

function ConnectScreen(props){
    const [appColor, setAppColor] = useState('#00BB30')
    const [customerData, setCustomerData] = useState({});
    const [providerData, setProviderData] = useState({});
    const [chatEnabled, setChatEnabled] = useState(true);
    const [messageBox, setMessageBox] = useState('');
    const [progressDialog, setProgressDialog] = useState('Loading Connect Section...');
    const [messages, setMessages] = useState([]);
    const [msgSending, setMsgSending] = useState(false);

    const firebaseApp = FirebaseUtil.initFirebase().firebaseApp;
    const database = firebaseApp.database();
    const auth = firebaseApp.auth();
    const storage = firebaseApp.storage();

    useEffect(() => {
        setAppColor(loadAppColor(appColor));
        
        auth.onAuthStateChanged(async (user) => {
            if(user && user.phoneNumber){
                let custData = await loadCustomerData(database, user.phoneNumber.replace('+91', ''));
                setCustomerData(custData);
                if(!custData.name){
                    ShowUpdateProfile();
                }

                let providerData = await LoadData(database, custData);
                
                if(providerData === "NO_APPOINTMENT"){
                    ShowConnectDisabled();
                }else setProviderData(providerData);

                setMessages([]);
                LoadChatMessages(database, custData.customerId, (msg) => {
                    setMessages(msgs => ([...msgs, msg]));
                    setTimeout(() => ScrollToBottom(), 500);
                });

                setProgressDialog(false);
            }
        });
    }, []);

    function sendAttachment(){
        if(msgSending) return;
        
        let fileSelector = document.getElementById('SelectAttachment');

        fileSelector.value = null;
        fileSelector.click();
    }

    async function OnFileSelected(file){
        let MAX_SIZE = 5 * 1024 * 1024; // 5 mg
        if(file.size >= MAX_SIZE){
            window.M.toast({html: "<span>Max file size: 5MB</span>"});
            return;
        }

        setMsgSending(true);
        setProgressDialog('Sending File...');

        let ref = storage.ref(`/Chat/${customerData.customerId}/${new Date().getTime()}.${file.type.split('/')[1]}`);
        await ref.put(file);

        let url = await ref.getDownloadURL();
        sendMessage(url);
        console.log(url);

        setProgressDialog(false);
        setMsgSending(false);
    }

    function sendMessage(msg){
        if(msg.trim().length === 0 || msgSending) return;

        setMsgSending(true);

        let time = new Date().getTime();

        database.ref(`/chat/${customerData.customerId}/${time}`).set({msg: msg});

        database.ref(`chat_preview/${customerData.customerId}`)
            .update({
                m: msg,
                d: providerData.doctorName,
                c: customerData.name,
                t: time
            });

        console.log(customerData);

        setMsgSending(false);
        setMessageBox('');
    }

    return(
        <div className="PatternBg" style={{height: "100%", width: "100%", display: "flex", flexDirection: "column"}}>
            <input onChange={change => OnFileSelected(change.target.files[0])} type="file" id="SelectAttachment" style={{display: "none"}}/>

            {/* Modal for chat disabled alert */}
            <div id="NoConnectModal" className="modal">
                <div className="modal-content">
                <h4>Connect not available</h4>
                <p>Please book an appointment to enable Connect Tab.</p>
                </div>
                <div className="modal-footer">
                    <Link to={RoutesConstants.Home} className="modal-close waves-effect waves-green btn-flat">Go Back</Link>
                    <Link to={RoutesConstants.Book} className="modal-close waves-effect waves-green btn-flat">Book</Link>
                </div>
            </div>

            {/* Modal for incomplete profile alert */}
            <div id="NoProfileModal" className="modal">
                <div className="modal-content">
                <h4>Incomplete Profile</h4>
                <p>Please update your profile to access Connect Section</p>
                </div>
                <div className="modal-footer">
                    <Link to={RoutesConstants.Home} className="modal-close waves-effect waves-green btn-flat">Back</Link>
                    <Link to={RoutesConstants.Profile} className="modal-close waves-effect waves-green btn-flat">Update Profile</Link>
                </div>
            </div>

            {progressDialog &&
                <ProgressDialog
                    head="Please Wait"
                    body={progressDialog}
                />
            }

            <div className="row" style={{padding: "10px", backgroundColor: appColor, width: "100%"}}>
                <div className="col col-2">
                    <span className="material-icons Clickable" style={{color: "white"}}>arrow_back_ios</span>
                </div>

                <h3 className="col col-10" style={{margin: "0", padding: "0", color: "white", fontSize: "1.4em"}}>
                    {providerData.clinicLocation}
                </h3>
            </div>

            <div id="ChatContainer" className="container" style={{width: "100%", flex: "1", overflowY: "auto", overflowX: "hidden", display: "flex", flexDirection: "column"}}>
                {messages.map(msg => {
                    return(
                        <ChatBubble 
                            key={msg.date + msg.msg}
                            appColor={appColor}
                            docName={msg.from ? providerData.doctorName : null}
                            message={msg.msg}
                            time={msg.date}
                        />
                    );
                })}
            </div>

            <div className="container" style={{width: "100%", display: "flex", flexDirection: "row", padding: "10px"}}>
                <a onClick={() => sendAttachment()} className="btn-floating btn-large waves-effect waves-light" style={{backgroundColor: appColor}}><i className="material-icons">attachment</i></a>
                <input value={messageBox} onChange={change => setMessageBox(change.target.value)} type="text" placeholder="Type your message here..." className="input-field" style={{flex: 1, margin: "0 10px", fontSize: "1.2em"}} />
                <a onClick={() => sendMessage(messageBox)} className="btn-floating btn-large waves-effect waves-light" style={{backgroundColor: appColor}}><i className="material-icons">send</i></a>
            </div>
        </div>
    );
}

function loadAppColor(def){
    let color = localStorage.getItem('appColor');
    return color ? color : def;
}

export default withRouter(ConnectScreen);