import React from 'react'
import { useHistory } from 'react-router-dom'
import './home.css';

function MenuList(props) {
  const { routeImages, routes } = props
  const history = useHistory()

  return (
    <div>
      {console.log(routes)}
      <div class='row'  style={{marginBottom: "0px"}}>
        {routes.map(r =>
          <div
            class='col s6 m4 l3' onClick={() => {
              if (r.link) {
                history.push(r.link)
              } else {
                window.M.toast({ html: 'Feature not available at this time. Thanks for your patience while we enable this', displayLength: 5000 });
              }
            }
            }
            key={r.key}
          >
            <div
              class='card home-tiles Clickable'
              style={
                { backgroundImage: 'url(' + routeImages[r.key || './logo512.png'] + ')' }
              }
            >
              <span className='card-title'>{r.name}</span>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default MenuList
