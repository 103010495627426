import React, { useState, useEffect } from 'react'
import { Link, withRouter } from 'react-router-dom'
import RoutesConstants from '../RoutesConstants'
import FirebaseUtil from '../FirebaseUtil/FirebaseUtil'
import Review from './Home/Review'
import BookAppts from './Home/BookAppts'
import MenuList from './Home/MenuList'

function HomeScreen(props) {
  const routes = [
    { name: 'My Appointments', link: RoutesConstants.Appointments, key: 'appointment' },
    { name: 'My Exercises', link: RoutesConstants.Exercises, key: 'exercise' },
    { name: 'My Connect', link: RoutesConstants.Connect, key: 'chat' },
    { name: 'Clinic Profile', link: RoutesConstants.Doctors, key: 'profile' }
  ]

  const firebaseApp = FirebaseUtil.initFirebase().firebaseApp
  const [routeImages, setRouteImages] = useState([])
  const [navInstance, setNavInstance] = useState()
  const [logo, setLogo] = useState('')
  const [appColor, setAppColor] = useState('')

  const [profile] = useState(() => {
    const customer = JSON.parse(localStorage.getItem('customer'))
    if (customer) return customer.profile
    return {}
  })

  useEffect(() => {
    const navBar = document.querySelector('#slide-out')
    window.M.Sidenav.init(navBar, null)
    setNavInstance(window.M.Sidenav.init(navBar, null))

    firebaseApp.database().ref('appSettings/bgColor').once('value').then(x => {
      if (x && x.val()) {
        setAppColor(x.val())
        localStorage.setItem('appColor', x.val())
      }
    })

    firebaseApp.database().ref('master/logo').once('value').then(x => {
      if (x && x.val()) {
        setLogo(x.val())
      }
    })

    firebaseApp.database().ref().child('homeTiles').once('value').then(x => {
      setRouteImages(x.val())
    })
  }, [])

  return (
    <div>
      <ul id='slide-out' className='sidenav' onClick={() => navInstance.close()}>
        <li><Link to={RoutesConstants.Book}>Book Appointment</Link></li>
        <li><Link to={RoutesConstants.Appointments}>My Appointment</Link></li>
        <li><Link to={RoutesConstants.Exercises}>My Exercises</Link></li>
        <li><Link to={RoutesConstants.Doctors}>Clinic Profile</Link></li>
        <li><Link to={RoutesConstants.Profile}>My Profile</Link></li>
        <li><a
          href onClick={(e) => {
            e.preventDefault()
            firebaseApp.auth().signOut()
            localStorage.setItem('customer', null)
            props.history.push('/')
          }}
        >Sign Out
            </a>
        </li>
      </ul>

      <div className='navbar-fixed'>
        <nav>
          <div className='nav-wrapper valign-wrapper' style={{ backgroundColor: appColor }}>
            <a href='#menu' className='brand-logo left'>
              <a href='#menu' data-target='slide-out' className='sidenav-trigger show-on-large'><i className='material-icons'>menu</i></a>
            </a>
            <a href={RoutesConstants.Profile} className='brand-logo right' style={{ top: '0' }}>
              <img alt='profile' src={profile.image ? profile.image : './images/default_profile.png'} className='profile-icon' />
            </a>
            <a href='#home' className='brand-logo center'>
              {/* <img style={{ width: '100px' }} src={logo} /> */}
            </a>
          </div>
        </nav>
      </div>

      <MenuList routeImages={routeImages} routes={routes} />
      <BookAppts routeImages={routeImages} />
      <Review routeImages={routeImages} />
    </div>
  )
}

export default withRouter(HomeScreen)
