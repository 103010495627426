import React, { useState, useEffect } from 'react';
import FirebaseUtil from '../../FirebaseUtil/FirebaseUtil';
import FirebaseApp from 'firebase/app';
import Utils from '../../Utils';
import ProgressDialog from '../../Components/ProgressDialog/ProgressDialog';
import ReactPlayer from "react-player"
import "./ExerciseList.css";
import { useHistory } from 'react-router-dom'
import NavHeader from '../../Components/NavHeader';
import RoutesConstants from '../../RoutesConstants'

let firebaseApp = FirebaseUtil.initFirebase().firebaseApp;
let firebaseAuth = FirebaseApp.auth(firebaseApp)
let database = FirebaseApp.database(firebaseApp)


function ExerciseList() {
  let exerciseListComp = '';
  const history = useHistory();
  const [exerciseKeys, setExerciseKeys] = useState({});
  const [phoneNumber, setPhoneNumber] = useState('');
  const [exerciseList, setExerciseList] = useState([]);
  const [loading, setloading] = useState(true);

  useEffect(() => {
    const getPhoneNum = () => {
      firebaseAuth.onAuthStateChanged(user => {
        if (user == null) {
          alert('Please login to view this page');
        }
        else {
          setPhoneNumber(user.phoneNumber.replace('+91', ''));
          setloading(true)
        }
      })
    }
    getPhoneNum();

  }, [])

  useEffect(() => {
    const getExercises = async () => {
      const data = await database
        .ref(`/customer/`)
        .orderByChild("profile/phone")
        .equalTo(phoneNumber)
        .once('value')

      if (data.val() != null && Utils.toArray(data.val())[0] && Utils.toArray(data.val())[0].exercises) {
        setExerciseKeys(Utils.toArray(data.val())[0].exercises)
      }
    }
    getExercises();
  }, [phoneNumber])

  useEffect(() => {
    const getExercises = async () => {
      const data = await database
        .ref(`/customer/`)
        .orderByChild("profile/phone")
        .equalTo(phoneNumber)
        .once('value')

      if (data.val() != null && Utils.toArray(data.val())[0] && Utils.toArray(data.val())[0].exercises) {
        setExerciseKeys(Utils.toArray(data.val())[0].exercises)
      }
    }
    getExercises();
  }, [phoneNumber])

  useEffect(() => {
    const exerciseKeyList = Object.keys(exerciseKeys);
    let promises = [];
    exerciseKeyList.forEach(key => {
      promises.push(database
        .ref(`others/exercises/${key}`)
        .once('value')
        .then((snap) => {
          return snap.val()
        })
      )
    })
    Promise.all(promises).then(p => {
      setExerciseList(p)
      setloading(false)
    })
  }, [exerciseKeys])


  if (exerciseList.length > 0) {
    exerciseListComp = (
      <ul className="collection with-header ExerciseList">
        {
          exerciseList.map((exercise, index) => (
            <li key={index} id="exercise-item" className=" z-depth-2">
              <div className="collection-header Exercise-Title grey lighten-1 ">
                <h6>{exercise.title}</h6>
              </div>
              <div className='row' style={{ marginBottom: 0 }}>
                <div className="col s12 Exercise-content" style={{ paddingLeft: 0, paddingRight: 0 }}>
                  <ReactPlayer controls url={exercise.url} width={390} height={150} light={exercise.thumbnail ? exercise.thumbnail : './images/default_thumbnail.jpg'}></ReactPlayer>
                </div>
              </div>
            </li>
          ))
        }
      </ul>
    )
  } else {
    exerciseListComp = <p>No Exercises</p>
  }

  return (
    <div className="ExerciseList-MainContainer SecondaryBg PatternBg">
      {loading === true &&
        <ProgressDialog head="Please Wait" body="Loading Exercises" />}
      <NavHeader title='My Exercises' />
      <div id="exercise-list">{exerciseListComp}</div>
    </div>
  );
}
export default ExerciseList;