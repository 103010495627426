import Firebase from 'firebase';

class FirebaseUtil {
    static instance = null;
    static firebaseApp;
    static Auth;
    static Database;

    constructor() {
        if (Firebase.apps.length === 0) {
            this.firebaseApp = Firebase.initializeApp(window.config);
            Firebase.analytics();

            this.Auth = this.firebaseApp.auth();
            this.Database = this.firebaseApp.database();
        }
    }

    static initFirebase() {
        if (!this.instance) {
            this.instance = new FirebaseUtil();
        }
        return this.instance;
    }

}

export default FirebaseUtil;
