import React from 'react';
import FirebaseApp from 'firebase/app';
import FirebaseUtil from '../../FirebaseUtil/FirebaseUtil';
import DocImg from '../../Resources/doctor.png';
import './ConfirmAppointment.css';
import ProgressDialog from '../ProgressDialog/ProgressDialog';
import Utils from '../../Utils';

const MAX_FILE_SIZE = 5 * 1024 * 1024; // 5mb

class ConfirmAppointment extends React.Component {


    constructor(props) {
        super(props);
        console.log(props)
        this.state = {
            filesList: [], // list of files
            patientType: 'remote',
            downloadUrls: [],
            currentFileUploading: 0,
            note: '',
            doctor: this.props.doctor || {},
            references: [],
            showProgress: false,
            progressConfig: {
                head: 'Loading',
                body: 'Please wait, loading details'
            }, // head, body
        }
        this.firebaseApp = FirebaseUtil.initFirebase().firebaseApp;
        this.storage = FirebaseApp.storage(this.firebaseApp);
        this.database = FirebaseApp.database(this.firebaseApp);
    }

    removeFileAtIndex(index) {
        this.state.filesList.splice(index, 1);
        this.forceUpdate();
    }

    getFilesListView() {
        return (
            <div className="ConfirmApt-PrescriptionContainer">
                {this.state.filesList.map((file, index) => {
                    return (
                        <div className="ConfirmApt-PrescriptionEntry PrimaryText">
                            <p>{file.name}</p>
                            <a onClick={() => this.removeFileAtIndex(index)}>X</a>
                        </div>
                    )
                })}

                {this.state.filesList.length === 0 &&
                    <p>No Files Selected</p>}
            </div>
        )
    }

    OnConfirmClicked() {
        this.uploadFile(0);
    }

    uploadFile(index) {
        // check if files exists
        if ((index + 1) > this.state.filesList.length) {
            this.setState({
                showProgress: false,
            }, () => this.bookAppointment());

            return;
        }

        this.setState({
            showProgress: true,
            progressConfig: {
                head: 'Please Wait',
                body: `Uploading File: ${index + 1} / ${this.state.filesList.length}`
            },
            currentFileUploading: index
        });

        const time = new Date().getTime();
        const reference = `/Consumer/Files/${this.props.customerId}_${time}.${this.state.filesList[index].name.split('.').pop()}`;
        this.state.references.push(reference);
        this.storage.ref(reference)
            .put(this.state.filesList[index])
            .then((rslt) => {
                this.storage.ref(reference).getDownloadURL()
                    .then((url) => {
                        this.state.downloadUrls.push(url);
                    })
                    .finally(() => {
                        this.uploadFile(index + 1);
                    });
            })
            .catch(err => {
                console.log('Error uplaoding file', this.state.filesList[index], err);
                this.uploadFile(index + 1);
            });
    }

    // used with loadAppointments() method to generate startAtNodeLable while quering data
    getStartAtDate() {
        const monthInTwoDigit = this.props.appointmentId.split('_')[2].substr(4, 2);
        const dateInTwoDigit = this.props.appointmentId.split('_')[2].substr(6, 2);
        const year = this.props.appointmentId.split('_')[2].substr(0, 4);
        const providerID = `${this.props.appointmentId.split('_')[0]}_${this.props.appointmentId.split('_')[1]}`;

        const dateStamp = `${year}${monthInTwoDigit}${dateInTwoDigit}`;
        const timeStamp = `${this.props.appointmentId.split('_')[2].substr(8, 4)}00`;
        return `${providerID}_${dateStamp}${timeStamp}`;
    }

    // used with loadAppointments() method to generate endAtNodeLable while quering data
    getEndAtDate() {
        const monthInTwoDigit = this.props.appointmentId.split('_')[2].substr(4, 2);
        const dateInTwoDigit = this.props.appointmentId.split('_')[2].substr(6, 2);
        const year = this.props.appointmentId.split('_')[2].substr(0, 4);
        const providerID = `${this.props.appointmentId.split('_')[0]}_${this.props.appointmentId.split('_')[1]}`;

        const dateStamp = `${year}${monthInTwoDigit}${dateInTwoDigit}`;
        const timeStamp = `${this.props.appointmentId.split('_')[2].substr(8, 4)}59`;
        return `${providerID}_${dateStamp}${timeStamp}`;
    }

    getPatientType() {
        if (this.state.patientType === 'out') {
            return 'Out Patient';
        }
        if (this.state.patientType === 'home') {
            return 'Home Visit';
        }
        return 'Remote';
    }

    bookSlot(finalAppointmentID) {
        this.database
            .ref('/appointments/')
            .child(finalAppointmentID)
            .update({
                status: 'CONFIRM',
                customerId: this.props.customerId,
                appointmentDetails: {
                    notes: `Post Covid Rehab(${this.props.postCovidType}), ` + this.state.note,
                    files: this.state.downloadUrls,
                    type: this.getPatientType(),
                },
            })
            .then(
                () => {
                    this.sendNotification(finalAppointmentID);
                    this.saveDetailInCustomerNode(finalAppointmentID);
                    this.setState({
                        showProgress: false
                    });
                    alert('Appointment Booked Successfully!\n You will soon be contacted by the clinic.\nThanks for choosing us');
                    this.props.GoBack();
                }
            )
            .catch(
                (error) => {
                    this.setState({
                        showProgress: false
                    });
                    console.log('Error booking appointmennt', error);
                    alert('Sorry\nThere seems to be some issue while communicating with our servers, Please try again later');
                    this.props.GoBack();
                },
            );
    }

    sendNotification(finalAppointmentID) {
        let dataToSend = {
            date: Utils.getTimeStampFromAppointmentId(finalAppointmentID),
            type: this.getPatientType(),
            status: "CONFIRM",
            customerId: this.props.customerId,
            appointmentId: finalAppointmentID
        };
        console.log("Sending notification", dataToSend);
        FirebaseApp.functions(this.firebaseApp).httpsCallable('apptNotification')(dataToSend).then(rslt => {
            console.log('Sent notification', rslt.data);
        }).catch(err => {
            console.log('Error sending notifications', err);
        });
    }

    saveDetailInCustomerNode(finalAppointmentID) {
        this.database
            .ref('/customer/')
            .child(this.props.customerId)
            .child('appointments')
            .update(
                {
                    [finalAppointmentID]: 'CONFIRM',
                },
            );
    }

    bookAppointment() {
        this.setState({
            showProgress: true,
            progressConfig: {
                head: 'Please Wait',
                body: 'Booking appointment...'
            }
        });

        this.database
            .ref('/appointments/')
            .orderByKey()
            .startAt(this.getStartAtDate())
            .endAt(this.getEndAtDate())
            .once('value')
            .then(
                (snap) => {
                    let finalSnap = null;
                    snap.forEach(
                        (appointment) => {
                            if (appointment.child('status').val() === 'INITIAL') {
                                finalSnap = appointment;
                            }
                        },
                    );
                    if (finalSnap !== null) {
                        console.log('Booking for', finalSnap.key);
                        this.bookSlot(finalSnap.key);
                    } else {
                        // else tell user that someone else booked the appointment and take him back
                        // to appointment schedule window and show an alert
                        this.setState({
                            showProgress: false,
                        }, () => {
                            this.deleteUploadedFiles();
                            alert('Sorry\n' + 'The slot just got booked, please choose another slot');
                            this.props.GoBack();
                        });
                    }
                }
            )
            .catch(
                (error) => {
                    this.setState({
                        showProgress: false
                    });
                    console.log('error retrieving appointment', error);
                    alert('Sorry\n' + 'There seems to be some error while fetching appointment. Please try again later');
                    this.props.GoBack();
                },
            );
    }

    deleteUploadedFiles() {
        this.state.references.forEach(
            (reference) => {
                this.storage.ref(reference).delete().then(
                    () => { },
                    () => { },
                )
                    .catch(
                        () => { },
                    );
            },
        );
    }

    onFilesSelected(files) {
        let allFilesUnderSize = true;

        for (let i = 0; i < files.length; i++) {
            if (files[i].size <= MAX_FILE_SIZE) {
                this.state.filesList.push(files[i]);
            } else allFilesUnderSize = false;
        }

        if (!allFilesUnderSize) {
            alert('Only Files under 5mb are allowed.')
        }
        this.forceUpdate();
    }

    render() {
        return (
            <>
                <div className="ConfirmApt-MainContainer">
                    <div className="ConfirmApt-InnerContainer">
                        <p className="ConfirmApt-Subtitle">Booking Details</p>

                        <div className="ConfirmApt-DocInfoContainer">
                            <img className="ConfirmApt-DocInfoImg" src={this.state.doctor.imageUrl || DocImg} />
                            <div className="ConfirmApt-DocInfoRight">
                                <p className="ConfirmApt-DocInfoName">{this.state.doctor.name}</p>
                                <p className="ConfirmApt-DocInfoDate PrimaryText">{Utils.getFormattedDate(Utils.getDateTimeFromKey(this.props.appointmentId), 'fullTime')}</p>
                            </div>
                        </div>

                        <div className="ConfirmApt-AppointmentRadios">
                            <label>
                                <input onChange={() => this.setState({ patientType: 'remote' })} type="radio" className="with-gap" checked={this.state.patientType === 'remote'} />
                                <span>Remote/Video</span>
                            </label>
                        </div>

                        <div className="ConfirmApt-Subtitle" style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center" }}>
                            <span style={{ flex: "1" }}>Upload Prescription/ Refferal Report</span>
                            <span onClick={() => this.fileInput.click()} className="material-icons" style={{ marginLeft: "20px", fontSize: "1.2em" }}>
                                upload
                            </span>
                        </div>
                        {this.getFilesListView()}

                        <p className="ConfirmApt-Subtitle">Enter Note</p>
                        <textarea
                            className="ConfirmApt-Note"
                            value={this.state.note}
                            onChange={ev => this.setState({ note: ev.target.value })}
                        />
                    </div>

                    <input
                        type="file"
                        multiple
                        style={{ display: "none" }}
                        ref={input => this.fileInput = input}
                        onChange={event => this.onFilesSelected(event.target.files)} />

                    <button onClick={() => this.OnConfirmClicked()} className="ConfirmApt-ConfirmBtn PrimaryBg SecondaryText">Book</button>
                </div>

                {this.state.showProgress &&
                    <ProgressDialog
                        head={this.state.progressConfig.head}
                        body={this.state.progressConfig.body} />
                }
            </>
        )
    }
}

export default ConfirmAppointment;
