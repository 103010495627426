import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import './DoctorList.css';
import Utils from '../../Utils'
import FirebaseUtil from '../../FirebaseUtil/FirebaseUtil'
import RoutesConstants from '../../RoutesConstants'
import NavHeader from '../../Components/NavHeader';

function DoctorList() {
  const firebaseApp = FirebaseUtil.initFirebase().firebaseApp
  const history = useHistory()
  const [clinics, setClinics] = useState([])
  const [doctors, setDoctors] = useState([])

  const [logo, setLogo] = useState('./images/default_profile.png')
  const [aboutUs, setAboutUs] = useState('About Us')

  useEffect(() => {

    firebaseApp
      .database()
      .ref('clinicList').once('value')
      .then(
        (data) => {
          setClinics(Utils.toArray(data.val()))
          window.M.FormSelect.init(document.querySelectorAll('select'))
          getDoctors(Utils.toArray(data.val())[0].key)
        })

    firebaseApp
      .database()
      .ref('master/logo').once('value')
      .then(
        (data) => {
          setLogo(data.val())
        })

    firebaseApp
      .database()
      .ref('appSettings/aboutUs').once('value')
      .then(
        (data) => {
          setAboutUs(data.val())
        })
  }, [])

  function getDoctors(key) {
    firebaseApp
      .database()
      .ref('provider/' + key + '/doctors').once('value')
      .then(
        (data) => {
          setDoctors(Utils.toArray(data.val()))
        })
  }

  const onInput = (e) => {
    console.log(e.target.value)
    getDoctors(e.target.value)
  }

  return <div class="row">
    <NavHeader title='Profile' />

    <div class='row mt-16'>
      <div class="profile-img-wrapper">
        <img src={logo} alt='' class='clinic-img' />
      </div>
    </div>

    <div class='row'>
      <div class="col s12"><p>{aboutUs}</p></div>
    </div>

    <div class="input-field col s12">
      <select onChange={onInput}>
        {clinics.map(c => {
          return <option value={c.key}> {c.name + ', ' + c.locality} </option>
        })}
      </select>
      <label>Clinics</label>
    </div>

    {doctors.length > 0 ?
      <ul className='collection'>
        {
          doctors.map((d, index) => (
            <li className='collection-item avatar' id='item-header' key={d.key}>

              <img alt='' src={d.profile.imageUrl} className='circle' id='list-item-image' />

              <span className='title PrimaryText' id='list-item-title'>{d.profile.name}</span>

              <p id='list-item-qualification'>{d.profile.qualification}</p>

              <p id='list-item-details'>{d.profile.doctorDetails}</p>
            </li>
          ))
        }
      </ul> : null}
  </div>
}
export default DoctorList
