import React from 'react';
import { withRouter } from 'react-router';
import ProgressDialog from '../../Components/ProgressDialog/ProgressDialog';
import FirebaseUtil from '../../FirebaseUtil/FirebaseUtil';
import './AppointmentList.css';
import FirebaseApp from 'firebase/app';
import { Link } from 'react-router-dom';
import AppointmentEntry from '../../Components/AppointmentEntry/AppointmentEntry';
import RoutesConstants from '../../RoutesConstants';
import NavHeader from '../../Components/NavHeader'

class AppointmentList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            appointments: [], // array with appointment objects
            loading: true,
        }

        this.firebaseApp = FirebaseUtil.initFirebase().firebaseApp;
        this.firebaseAuth = FirebaseApp.auth(this.firebaseApp);
        this.database = FirebaseApp.database(this.firebaseApp);
        this.functions = FirebaseApp.functions(this.firebaseApp);
    }

    componentDidMount() {
        document.title = "My Appointments";

        if (this.state.loading)
            this.loadProfile();
    }

    loadProfile() {
        this.firebaseAuth.onAuthStateChanged(user => {
            if (user == null) {
                alert('Please login to view this page');
                this.props.history.push(RoutesConstants.Home);
            } else {
                const phoneNumber = user.phoneNumber.replace('+91', '');
                this.database
                    .ref('/customer/')
                    .orderByChild('profile/phone')
                    .equalTo(phoneNumber)
                    .once('value')
                    .then(snap => {
                        if (snap.exists() && Object.keys(snap.val()).length > 0) {
                            this.customerId = Object.keys(snap.val())[0];
                        } else console.log('Doesntexist');
                    }).catch(err => {
                        console.log('Error getting customerId', err);
                    }).finally(() => {
                        this.loadAppointments();
                    });
            }
        });
    }

    loadAppointments() {
        console.log('Appontments for', this.customerId);
        this.functions
            .httpsCallable('getCustomerAppointments')({ customerId: this.customerId })
            .then(res => {
                console.log(res.data);
                this.setState({
                    appointments: res.data.appointments
                })
            })
            .catch(err => {
                console.log('Error', err);
            })
            .finally(() => {
                this.setState({
                    loading: false,
                });
            })
    }

    GoBack() {
        this.props.history.push(RoutesConstants.Home);
    }

    getUpcomingAppointments() {
        let currentDate = new Date().getTime();
        let upcomingAppointments = [];
        this.state.appointments.forEach(app => {
            if (app.date >= currentDate)
                upcomingAppointments.push(app);
        });

        if (upcomingAppointments.length > 0)
            return (
                <div style={{ width: "100%" }}>
                    <p className="AppointmentList-Subtitle">Upcoming Appointments</p>
                    {upcomingAppointments.map(app => {
                        return (
                            <AppointmentEntry appointment={app} />
                        );
                    })}
                </div>
            );
    }

    getPreviousAppointments() {
        let currentDate = new Date().getTime();
        let previousAppointments = [];
        this.state.appointments.forEach(app => {
            if (app.date < currentDate)
                previousAppointments.push(app);
        });

        if (previousAppointments.length > 0)
            return (
                <div style={{ width: "100%" }}>
                    <p className="AppointmentList-Subtitle">Previous Appointments</p>
                    {previousAppointments.map(app => {
                        return (
                            <AppointmentEntry appointment={app} />
                        );
                    })}
                </div>
            );
    }

    render() {
        return (
            <div>
                <NavHeader title='My Appointments' link={RoutesConstants.Home} />
                <div className="AppointmentList-MainContainer SecondaryBg PatternBg">
                    {this.state.loading &&
                        <ProgressDialog head="Please Wait" body="Loading Appointments" />
                    }

                    <Link className="AppointmentList-Book PrimaryText" to={RoutesConstants.Request}>Request Appointment</Link>

                    {this.getUpcomingAppointments()}
                    {this.getPreviousAppointments()}

                </div>
            </div>
        )
    }

}

export default withRouter(AppointmentList);
