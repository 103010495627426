import FirebaseUtil from './FirebaseUtil/FirebaseUtil';
import Firebase from 'firebase/app';

export default function InitServiceWorker() {
  const firebaseApp = FirebaseUtil.initFirebase().firebaseApp;
  const messaging = Firebase.messaging(firebaseApp);
  if ('serviceWorker' in navigator) {
    window.addEventListener('load', function () {
      navigator.serviceWorker.register('service-worker.js').then(function (registration) {
        console.log('ServiceWorker registration successful: ', registration.scope);

        // setting up notifications
        Notification.requestPermission()
          .then(async res => {
            console.log('Got msg permission', res);
            if (res === "granted") {
              const tkn = await messaging.getToken({ serviceWorkerRegistration: registration });
              window.firebaseToken = tkn;
              console.log('Token', tkn);

              try {
                firebaseApp.messaging();
              } catch (ex) {
                console.log('Error initializing firebase messaging ', ex);
              }
            }
          })
          .catch(err => {
            console.log('error getting msg permission', err);
          });
      }).catch(err => {
        console.log('Serviceworker reg failed:', err);
      });
    });
  } else {
    console.log('service worker is not supported');
  }
}