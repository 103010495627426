import React from 'react'
import './ScheduleTable.css'
import FirebaseApp from 'firebase/app'
import FirebaseUtil from '../../FirebaseUtil/FirebaseUtil'
import Utils from '../../Utils'
import ProgressDialog from '../ProgressDialog/ProgressDialog'

class ScheduleTable extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      slotsInfo: [{}, {}, {}], // 3 objects in an array containing timeStamp and as their value available slots
      dates: this.getDates(this.props.currentDate),
      selectedSlots: ['', '', ''], // slot ids of all three tabs
      showProgress: true
    }
    this.firebaseUtil = FirebaseUtil.initFirebase()
    this.database = FirebaseApp.database(this.firebaseUtil.firebaseApp)
  }

  componentDidUpdate(prevProp, prevState) {
    console.log(this.props.providerId)

    if (this.props.providerId !== prevProp.providerId || this.props.currentDate !== prevProp.currentDate) {
      this.setState({
        slotsInfo: [{}, {}, {}],
        dates: this.getDates(this.props.currentDate),
        selectedSlots: ['', '', '']
      }, () => this.loadAppointments())
    }
  }

  getDates(date) {
    const d2 = new Date(date);
    d2.setDate(date.getDate() + 1)

    const d3 = new Date(date);
    d3.setDate(date.getDate() + 2)

    return [date, d2, d3]
  }

  componentDidMount() {
    this.MTabs = window.M.Tabs.init(this.Tabs, {
      swipeable: true,
      onShow: (el) => this.forceUpdate()
    })

    this.loadAppointments()
  }

  loadAppointments() {
    this.setState({
      showProgress: true
    })

    this.state.dates.forEach((date, index) => {
      this.props.times.forEach((time) => {
        this.database
          .ref('/appointments/')
          .orderByKey()
          .startAt(this.getStartAtDate(time.sTime, date))
          .endAt(this.getEndAtDate(time.eTime, date))
          .once('value')
          .then(
            (snap) => {
              snap.forEach(
                (app) => {
                  if (app.child('status').val() === 'INITIAL') {
                    this.state.slotsInfo[index][this.getMiletaryTimeFromNodeLable(app.key)] = {
                      readableTime: this.convertMilitaryTimeTo12Hr(
                        this.getMiletaryTimeFromNodeLable(app.key)
                      )
                    }
                  }
                }
              )
              this.forceUpdate()
              console.log(this.state.slotsInfo)
            },
            (error) => {
              this.databaseErrorOccured(error)
            }
          )
          .catch(
            (error) => {
              this.databaseErrorOccured(error)
            }
          ).finally(() => {
            this.setState({
              showProgress: false
            })
          })
      })
    })
  }

  databaseErrorOccured(err) {
    console.log('Error', err)
  }

  // used with loadAppointments() method to generate startAtNodeLable while quering data
  getStartAtDate(startTime, date) {
    let monthInTwoDigit = new String(date.getMonth() + 1)
    if (monthInTwoDigit.length === 1) monthInTwoDigit = `0${monthInTwoDigit}`

    let dateInTwoDigit = new String(date.getDate())
    if (dateInTwoDigit.length === 1) dateInTwoDigit = `0${dateInTwoDigit}`

    const dateStamp = `${date.getFullYear()}${monthInTwoDigit}${dateInTwoDigit}`
    const timeStamp = `${startTime}00`
    return `${this.props.providerId}_${dateStamp}${timeStamp}`
  }

  // used with loadAppointments() method to generate endAtNodeLable while quering data
  getEndAtDate(endTime, date) {
    let monthInTwoDigit = new String(date.getMonth() + 1)
    if (monthInTwoDigit.length === 1) monthInTwoDigit = `0${monthInTwoDigit}`

    let dateInTwoDigit = new String(date.getDate())
    if (dateInTwoDigit.length === 1) dateInTwoDigit = `0${dateInTwoDigit}`

    const dateStamp = `${date.getFullYear()}${monthInTwoDigit}${dateInTwoDigit}`
    const timeStamp = `${endTime}59`
    return `${this.props.providerId}_${dateStamp}${timeStamp}`
  }

  // extracts miletary timeStamp from nodelable
  getMiletaryTimeFromNodeLable(lable = '') {
    let timeStamp = lable.split('_')[2].substr(8, 4)
    if (timeStamp[0] === '0') timeStamp = timeStamp.substr(1, 3)
    return timeStamp
  }

  // converts for example 0900 to 09:00 AM && 1630 to 16:30
  convertMilitaryTimeTo12Hr(militaryTime = '') {
    let mMilitaryTime = militaryTime
    if (mMilitaryTime.length === 3) {
      mMilitaryTime = `0${militaryTime}`
    }
    let hr = parseInt(mMilitaryTime.substr(0, 2), 10)
    const meredian = hr >= 12 ? 'PM' : 'AM'
    let min = parseInt(mMilitaryTime.substr(2, 2), 10)

    // converting to 12 hr
    hr = hr > 12 ? hr - 12 : hr
    // converting to 2 digit
    hr = hr < 10 ? `0${hr}` : hr
    min = min < 10 ? `0${min}` : min

    return `${hr}:${min} ${meredian}`
  }

  selectSlot(index, slot) {
    this.state.selectedSlots[index] = slot
    this.forceUpdate()
  }

  getNodeLableFromSelectedMilitaryTime(index, selectedSlot) {
    const date = this.state.dates[index]
    const year = date.getFullYear()

    let monthInTwoDigit = new String(date.getMonth() + 1)
    if (monthInTwoDigit.length === 1) monthInTwoDigit = `0${monthInTwoDigit}`

    let dateInTwoDigit = new String(date.getDate())
    if (dateInTwoDigit.length === 1) dateInTwoDigit = `0${dateInTwoDigit}`

    let timeStampTillHour = new String(selectedSlot)
    if (timeStampTillHour.length === 3) timeStampTillHour = `0${timeStampTillHour}`

    return `${this.props.providerId}_${year}${monthInTwoDigit}${dateInTwoDigit}${timeStampTillHour}00`
  }

  getSchedule(index) {
    if (Object.keys(this.state.slotsInfo[index]).length === 0) {
      return (
        <p className='center-align'>
          No Appointments Available
        </p>
      )
    }

    return (
      <div className='ScheduleTable-Schedule'>
        {Object.keys(this.state.slotsInfo[index]).map(key => {
          if (new Date().getTime() <= Utils.getTimeStampFromAppointmentId(this.getNodeLableFromSelectedMilitaryTime(index, key))) {
            return (
              <p
                className={this.state.selectedSlots[index] === key ? 'SlotSelected' : 'Slot'}
                onClick={() => this.selectSlot(index, key)}
              >
                {this.state.slotsInfo[index][key].readableTime}
              </p>
            )
          } else {
            return (
              <p
                className='SlotDisabled'
                onClick={() => { }}
              >
                {this.state.slotsInfo[index][key].readableTime}
              </p>
            )
          }
        })}
      </div>
    )
  }

  OnBookClicked() {
    console.log(this.getNodeLableFromSelectedMilitaryTime(this.MTabs.index, this.state.selectedSlots[this.MTabs.index]))
    this.props.onSelected(this.getNodeLableFromSelectedMilitaryTime(this.MTabs.index, this.state.selectedSlots[this.MTabs.index]))
  }

  render() {
    return (
      <>
        <div className='ScheduleTable-MainContainer'>
          <div className='ScheduleTable-InnerContainer'>
            <div style={{ width: '100%' }} className='row'>
              <div className='col s12'>
                <ul className='tabs ScheduleTable-Tabs' ref={Tabs => this.Tabs = Tabs}>
                  <li className='tab col s3'><a href='#SlotWindow1'>{`${this.state.dates[0].getDate()} ${Utils.getMonthShortNameFromIndex(this.state.dates[0].getMonth())}`}</a></li>
                  <li className='tab col s4'><a href='#SlotWindow2'>{`${this.state.dates[1].getDate()} ${Utils.getMonthShortNameFromIndex(this.state.dates[1].getMonth())}`}</a></li>
                  <li className='tab col s3'><a href='#SlotWindow3'>{`${this.state.dates[2].getDate()} ${Utils.getMonthShortNameFromIndex(this.state.dates[2].getMonth())}`}</a></li>
                  <li style={{ height: "48px" }} className="col s2 center-align valign-wrapper Clickable">
                    <a style={{ color: "var(--primary-color)" }} onClick={() => this.props.OpenCalendar()}><i className='material-icons'>today</i></a>
                  </li>
                </ul>
              </div>
            </div>

            <div id='SlotWindow1' className='col s12 ScheduleTable-TabView'>{this.getSchedule(0)}</div>
            <div id='SlotWindow2' className='col s12 ScheduleTable-TabView'>{this.getSchedule(1)}</div>
            <div id='SlotWindow3' className='col s12 ScheduleTable-TabView'>{this.getSchedule(2)}</div>
          </div>

          {
            this.MTabs && this.state.selectedSlots[this.MTabs.index] !== ''
              ? <button onClick={() => this.OnBookClicked()} className='ScheduleTable-BookBtn PrimaryBg SecondaryText'>Book</button>
              : <button className='ScheduleTable-BookBtn' style={{ backgroundColor: '#e2e2e2', color: 'black' }}>Book</button>
          }

        </div>

        {this.state.showProgress &&
          <ProgressDialog head='Please Wait' body='Loading Available Appointments' />}
      </>
    )
  }
}

export default ScheduleTable
