import React from 'react';
import IcSpinner from '../../Resources/ic_spinner.svg';
import './ProgressDialog.css';

class ProgressDialog extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            appColor: localStorage.getItem('appColor'),
        }
    }

    render(){
        return(
            <div className="Progress-MainContainer">
                <div className="Progress-Dialog">
                    <p className="Progress-Heading" style={{backgroundColor: this.state.appColor, color: "white"}}>{this.props.head}</p>
                    <div className="Progress-DialogBody" style={{backgroundColor: "white"}}>
                        <img src={IcSpinner} />
                        <p>{this.props.body}</p>
                    </div>
                </div>
            </div>
        )
    }
}

export default ProgressDialog;
