import React, { useState, useEffect } from 'react'
import FirebaseUtil from '../../FirebaseUtil/FirebaseUtil'

function Review(props) {
  const { routeImages } = props
  const firebaseApp = FirebaseUtil.initFirebase().firebaseApp

  const [reviewClinic, setReviewClinic] = useState(false)

  useEffect(() => {
    firebaseApp.database().ref().child('customerApp/reviewLink').once('value').then(x => {
      if (x && x.val()) {
        setReviewClinic({
          link: x.val(),
          key: 'review'
        })
      }
    })
  })

  return (
    reviewClinic
      ? <div class='row'>
        <div
          class='col s12 m7' onClick={() => window.open(reviewClinic.link)}
          key={reviewClinic.key}
        >
          <div
            class='card home-tiles Clickable'
            style={
              { backgroundImage: 'url(' + routeImages[reviewClinic.key || './logo512.png'] + ')' }
            }
          >
            <span class='card-title'>Review Our Clinic</span>
          </div>
        </div>
      </div> : null
  )
}

export default Review
