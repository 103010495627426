import { useEffect, useState } from "react";
import { withRouter } from "react-router"
import ProgressDialog from "../../Components/ProgressDialog/ProgressDialog";
import FirebaseUtil from "../../FirebaseUtil/FirebaseUtil";
import FirebaseApp from 'firebase/app';
import Utils from "../../Utils";
import NavHeader from "../../Components/NavHeader";



function RequestAppointment(props) {

    const firebaseApp = FirebaseUtil.initFirebase().firebaseApp;
    const database = FirebaseApp.database(firebaseApp);

    const [appointmentType, setAppointmentType] = useState('Walk in');
    const [aptDate, setAptDate] = useState(new Date());
    const [description, setDescription] = useState('');
    const [progress, setProgress] = useState('Loading Request Appointment');
    const [customer, setCustomer] = useState({});

    useEffect(() => {
        window.M.Datepicker.init(document.getElementById('AppointmentDate'), {
            format: 'dd-mm-yyyy'
        });

        window.M.Timepicker.init(document.getElementById('AppointmentTime'));

        // loading Customer Details
        firebaseApp.auth().onAuthStateChanged((user) => {
            console.log(user.phoneNumber)
            const phoneNumber = user.phoneNumber.replace('+91', '')
            database
                .ref('/customer/')
                .orderByChild('profile/phone')
                .equalTo(phoneNumber)
                .once('value')
                .then(snap => {
                    if (snap && snap.val()) {
                        const p = Object.values(snap.val())[0].profile || {};
                        const key = Object.keys(snap.val())[0];
                        setCustomer({ ...p, key: key });
                        setProgress(null)
                    } else console.log('Doesnt exist')
                }).catch(err => {
                    console.log('Error getting customerId', err)
                })
        });
    }, []);

    const sendRequest = async () => {
        let datePicker = window.M.Datepicker.getInstance(document.getElementById('AppointmentDate'));
        let timePicker = window.M.Timepicker.getInstance(document.getElementById('AppointmentTime'));

        console.log(datePicker, timePicker);

        if (!datePicker.date) {
            alert('Please select date');
            return;
        }
        if (timePicker.hours === undefined || timePicker.minutes === undefined) {
            alert('Please select time');
            return;
        }


        let finalTime = new Date(datePicker.date.getTime());
        let hours = timePicker.amOrPm === "PM" ? timePicker.hours + 12 : timePicker.hours;
        finalTime.setHours(hours, timePicker.minutes, 0, 0);
        var note = `${appointmentType} appt request from Patient App: ${Utils.getShortReadableTimeFromTimeStamp(finalTime.getTime())} ${hours}:${timePicker.minutes}:${0}, ${description}`;

        setProgress('Requesting Appointment...');

        let currTime = new Date().getTime();

        await firebaseApp.database()
            .ref(`customer/${customer.key}/enquiry/${currTime}/`)
            .set({
                time: currTime,
                notes: note,
            });

        await firebaseApp.database()
            .ref(`customer/${customer.key}/profile/lastEnquiry/`)
            .set(currTime);

        setProgress(null);

        window.M.toast({
            html: 'Appointment Requested Successfully'
        });

        setTimeout(() => props.history.push('/'), 1000);

    }


    return (
        <div>
            {progress !== null &&
                <ProgressDialog
                    head="Please Wait"
                    body={progress} />
            }

            <NavHeader title='Request Appointment' />

            <div className="container">
                <br></br>
                <label>
                    <input checked={appointmentType === 'Walk in'} name="reqType" type="radio" onChange={() => setAppointmentType('Walk in')}></input>
                    <span>Walk in</span>
                </label>
                <br></br>
                <label>
                    <input checked={appointmentType === 'Home Visit'} name="reqType" type="radio" onChange={() => setAppointmentType('Home Visit')}></input>
                    <span>Home Visit</span>
                </label>
                <br></br>
                <label>
                    <input checked={appointmentType === 'Online Video/Tele'} name="reqType" type="radio" onChange={() => setAppointmentType('Online Video/Tele')}></input>
                    <span>Online Video/Tele</span>
                </label>
                <br></br><br></br>

                <label>Select Date</label>
                <input id="AppointmentDate" type="text" className="datepicker"></input>
                <br></br><br></br>

                <label>Select Time</label>
                <input id="AppointmentTime" type="text" className="timepicker"></input>

                <br></br><br></br>

                <label>Description/ Comment</label>
                <input value={description} onChange={(ev) => setDescription(ev.target.value)} type="text" className="input-field"></input>

                <br></br><br></br>
                <a onClick={() => sendRequest()} style={{ width: "100%" }} className="waves-effect waves-light btn">Request Appointment</a>
            </div>
        </div>
    )
}

export default withRouter(RequestAppointment);
