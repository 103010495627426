import React from 'react'
import { useHistory } from 'react-router-dom'

function NavHeader (props) {
  const history = useHistory()
  return (
    <div class='navbar-fixed'>
      <nav>
        <div class='nav-wrapper' style={{ backgroundColor: localStorage.getItem('appColor') }}>
          <span class='brand-logo left'>
            <a
              href='#menu' data-target='slide-out' class='sidenav-trigger show-on-large' onClick={() => {
                if (props.link) {
                  history.push(props.link)
                } else {
                  history.goBack()
                }
              }}
            ><i class='material-icons'>arrow_back</i>
            </a>
          </span>
          <a href='#home' class='brand-logo center full-width'>
            <h5> {props.title} </h5>
          </a>
        </div>
      </nav>
    </div>
  )
}

export default NavHeader
